import { createSlice } from "@reduxjs/toolkit";
import { questionApi } from "../services/questionsApi";

const initialState = {
  priceGuessing: null,
  mcq: null,
};

export const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        questionApi.endpoints.getAllPriceGuessing.matchFulfilled,
        (state, { payload }) => {
          state.priceGuessing = payload.data;
        }
      )
      .addMatcher(
        questionApi.endpoints.getAllPriceGuessing.matchRejected,
        (state) => {
          state.priceGuessing = null;
        }
      )
      .addMatcher(
        questionApi.endpoints.getAllMcq.matchFulfilled,
        (state, { payload }) => {
          state.mcq = payload.data;
        }
      )
      .addMatcher(questionApi.endpoints.getAllMcq.matchRejected, (state) => {
        state.mcq = null;
      });
  },
});

export const { setDashboardInfo, unsetDashboardInfo } = questionSlice.actions;

export default questionSlice.reducer;
