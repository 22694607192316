import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/mcq_management.module.scss";

// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import EditSessionModal from "../../Components/Modals/EditSessionModal";
import {
  useAddSessionMutation,
  useDeleteSessionMutation,
  useGetSessionsQuery,
  useUpdateSessionMutation,
} from "../../Data/services/sessionApi";

const SessionManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetSessionsQuery({ refetchOnMountOrArgChange: true });

  var sessionData = useSelector((state) => state.sessions.data);

  useEffect(() => {
    setRowData(sessionData);
  }, [sessionData]);

  // const dummyData = [
  //   {
  //     id: 1,
  //     name: "Luck Duck",
  //     description: "Lorem Ipsum Lines",
  //     round_one_start_at: "2024-09-28T15:00:04.000Z",
  //     round_one_end_at: "2024-09-28T15:00:10.000Z",
  //     round_two_start_at: "2024-09-28T15:00:04.000Z",
  //     round_two_end_at: "2024-09-28T15:00:10.000Z",
  //     round_three_start_at: "2024-09-28T15:00:04.000Z",
  //     round_three_end_at: "2024-09-28T15:00:10.000Z",
  //     round_four_start_at: "2024-09-28T15:00:04.000Z",
  //     round_four_end_at: "2024-09-28T15:00:10.000Z",
  //     status: "active",
  //   },
  // ];

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    // toast("Here is your toast.");
    // console.log("toast");
    setEditModal(true);
  };

  const [deleteUser, { isLoading: isDeleting }] = useDeleteSessionMutation();

  const deleteData = async () => {
    console.log(editedData.id);

    const id = editedData.id;

    try {
      const { data } = await deleteUser(id);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data?.message);
      }
    } catch (error) {
      console.log(error);
      Toast("Sorry Something went wrong", "error");
    }
  };

  const [addSession] = useAddSessionMutation();

  const handleAdd = async (data) => {
    console.log(data);

    try {
      const response = await addSession(data);

      console.log(response);

      if (response?.error?.data?.code === 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response?.data?.message);
      }

    } catch (error) {
      Toast("Sorry Something went wrong", "error");
    }
  };

  const [updateUser, { isLoading: updateLoading }] = useUpdateSessionMutation();

  const handleUpdate = async (formData) => {
    const id = editedData.slug;

    console.log(formData);

    try {
      const response = await updateUser({
        modifiedData: formData,
        id: editedData.id,
      });
      console.log(response);

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      Toast("Sorry Something went wrong", "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return isLoading && <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "name",
      text: "Session Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    // {
    //   dataField: "round_one_start_at",
    //   text: "Round 1 Start",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_one_start_at);
    //     if (row.round_one_start_at !== null) {
    //       return convertToLocalTime(row.round_one_start_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_one_end_at",
    //   text: "Round 1 End",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_one_start_at);
    //     if (row.round_one_end_at !== null) {
    //       return convertToLocalTime(row.round_one_end_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_two_start_at",
    //   text: "Round 2 Start",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_two_start_at);
    //     if (row.round_two_start_at !== null) {
    //       return convertToLocalTime(row.round_two_start_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_two_end_at",
    //   text: "Round 2 End",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_one_start_at);
    //     if (row.round_two_end_at !== null) {
    //       return convertToLocalTime(row.round_two_end_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_three_start_at",
    //   text: "Round 3 Start",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_two_start_at);
    //     if (row.round_three_start_at !== null) {
    //       return convertToLocalTime(row.round_three_start_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_three_end_at",
    //   text: "Round 3 End",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_one_start_at);
    //     if (row.round_three_end_at !== null) {
    //       return convertToLocalTime(row.round_three_end_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_four_start_at",
    //   text: "Round 4 Start",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_two_start_at);
    //     if (row.round_four_start_at !== null) {
    //       return convertToLocalTime(row.round_four_start_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },
    // {
    //   dataField: "round_four_end_at",
    //   text: "Round 4 End",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //     textAlign: "center",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row.round_one_start_at);
    //     if (row.round_four_end_at !== null) {
    //       return convertToLocalTime(row.round_four_end_at);
    //     } else {
    //       return "No date";
    //     }
    //   },
    // },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "active") {
          return <div className={` ${styles.active}`}> Active </div>;
        } else {
          return <div className={`${styles.block}`}>Completed</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "140px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditSessionModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Session Management"} classes="black-text" />
        </div>
        <Row>
          <Col md={12}>
            {
              <Button
                classes={`${styles.cardBtn} `}
                text="Add Session"
                onClick={handleAddUser}
              ></Button>
            }
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData || []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default SessionManagementScreen;
